import React from "react";
import { Button } from "@mui/material";
import "./Residency.css";

function Residency() {
  return (
    <div className="residencyPage">
      <div className="residencyContainer">
        <div
          onClick={() => (window.location.href = "/")}
          style={{ cursor: "pointer" }}
        >
          <img src="v2-white.svg" className="residencyV2Logo" />
        </div>
        <h1>
          A collective house at the heart of Vancouver, designed to enable those
          who want to build the future.
        </h1>
        <p style={{ fontSize: "22px" }}>
          A gathering point for the ambitious to create across tech, science,
          art, music, film, design, and more.{" "}
        </p>
        <p style={{ marginTop: "50px", fontSize: "22px" }}>
          Apply for our January 2025 residency cycle by{" "}
          <span style={{ fontWeight: "bold" }}>November 22, 2024</span>.
        </p>
        <Button
          variant="outlined"
          className="subscribeButton"
          href="https://tally.so/r/mRBNxP"
          style={{
            fontSize: "20px",
            borderColor: "#954F51",
            borderRadius: "0px",
            color: "#954F51",
            maxWidth: "300px",
            maxHeight: "50px",
            minWidth: "300px",
            minHeight: "50px",
            marginTop: "70px",
            backgroundColor: "#DFDFDF",
            fontFamily: "DMMono",
          }}
        >
          VIEW APPLICATION FORM
        </Button>
        <hr
          style={{
            border: "none",
            borderTop: "2px dotted white",
            margin: "85px 0 70px 0",
          }}
        />
        <div className="residencySection">
          <h2>About our Residency Program</h2>
          <p>
            We run 4-month cycles, starting January 2025. Commit to working on
            an ambitious project in a House designed for making extraordinary
            things.
          </p>
          <p style={{ marginTop: "35px" }}>
            The perfect balance of individual deep-work spaces and programming,
            including:
            <ul>
              <li>regular demo sessions</li>
              <li>meetings with mentors in your discipline</li>
              <li>
                wider community events such as jam sessions, film nights, and
                more
              </li>
            </ul>
          </p>
          <p style={{ marginTop: "35px" }}>
            Example Projects:
            <ul>
              <li>
                building an early-stage biotech start up for rapid point-of-care
                diagnostics
              </li>
              <li>
                directing and producing of feature length documentary about the
                climate crisis and upcoming technologies
              </li>
              <li>
                researching how the intersection of linguistics and music theory
                can accelerate language learning
              </li>
              {/* <li>your own idea, whatever it may be</li> */}
            </ul>
          </p>
          <p style={{ marginTop: "50px" }}>
            Partnered with{" "}
            <a
              href="https://www.livetheresidency.com/"
              style={{ color: "#F27679" }}
            >
              The Residency
            </a>{" "}
            to bring a worldwide house network to Vancouver.
          </p>
        </div>
        <div className="residencySection">
          <h2>What We Provide</h2>
          <p>
            <ul>
              <li>
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                  All the resources you need to focus on actualizing your
                  project.
                </p>
                <p style={{ marginTop: "2px", fontStyle: "italic" }}>
                  Think individual deep work spaces, collaborative and hands-on
                  building spaces, rapid prototyping and iteration, whiteboards!
                </p>
              </li>
              <li>
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                  An environment curated towards mutual accountability and
                  feedback culture.
                </p>
                <p style={{ marginTop: "2px", fontStyle: "italic" }}>
                  Weekly demos, milestones, mentors, and more.
                </p>
              </li>
              <li>
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                  A vibrant, communal lifestyle.
                </p>
                <p style={{ marginTop: "2px", fontStyle: "italic" }}>
                  Large windows, collective living spaces, diverse people, safe
                  neighbourhood.
                </p>
              </li>
              <li>
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                  Collaboration with thought-partners and project advisors in
                  the community.
                </p>
                <p style={{ marginTop: "2px", fontStyle: "italic" }}>
                  Top-class workshops and mentorship, showcase evenings, and
                  more.
                </p>
              </li>
            </ul>
          </p>
        </div>
        <div className="residencySection">
          <h2>Who You Are</h2>
          <p>
            <ul>
              <li style={{ marginBottom: "8px" }}>
                <span style={{ fontWeight: "bold" }}>Doer.</span> You have a
                track record of actualizing your ideas, and you want to bring
                your current idea/project to the next level.
              </li>
              <li style={{ marginBottom: "8px" }}>
                <span style={{ fontWeight: "bold" }}>Cheerleader.</span> You
                don't just strive for excellence in your own life, but you
                support and celebrate the people around you.
              </li>
              <li style={{ marginBottom: "8px" }}>
                <span style={{ fontWeight: "bold" }}>Gardener.</span> You see
                the needs in the communities around you, and you act upon it.
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  Responsible Cohabitor.
                </span>{" "}
                You take ownership of the spaces you live in.
              </li>
            </ul>
          </p>
        </div>
        <div className="residencySection">
          <h2>More details</h2>
          <p>
            <ul>
              <li>5-8 Bedrooms and 3+ Washrooms</li>
              <li>
                ~$1400/mo per person to pay for rent + ~$100/mo for programming
                <span style={{ color: "#F27679" }}>*</span>
              </li>
              <li>
                Partnered with The Residency, which runs 10 houses around the
                world. Guest rooms are available in each city during the
                program.
              </li>
              <li>Balanced gender ratio</li>
            </ul>
            <span style={{ color: "#F27679" }}>
              *Please do not be discouraged from applying if you cannot afford
              residency; some spots will be available for sponsorship.
            </span>
          </p>
        </div>

        <Button
          variant="outlined"
          className="subscribeButton"
          href="https://tally.so/r/mRBNxP"
          style={{
            fontSize: "20px",
            borderColor: "#954F51",
            borderRadius: "0px",
            color: "#954F51",
            maxWidth: "300px",
            maxHeight: "50px",
            minWidth: "300px",
            minHeight: "50px",
            marginTop: "70px",
            backgroundColor: "#DFDFDF",
            fontFamily: "DMMono",
          }}
        >
          VIEW APPLICATION FORM
        </Button>
        <hr
          style={{
            border: "none",
            borderTop: "2px dotted white",
            margin: "70px 0",
          }}
        />
        <h1>FAQ</h1>
        <div className="residencySection">
          <h2>Can I work or be enrolled in classes?</h2>
          <p>
            Yes. Though we expect all residents to be working and sharing
            progress on an ambitious project while they are living at the house,
            it can be a part-time or full-time endeavour.
          </p>
        </div>
        <div className="residencySection">
          <h2>What are the living facilities like?</h2>
          <p>
            <ul>
              <li>
                5-8 bedrooms and 3+ bathrooms, large kitchen & dining room,
                large and spacious communal areas.
              </li>
              <li>
                Large windows, good lighting, plants, instruments, board games,
                library, tasteful decor.
              </li>
              <li>Exercise area & more.</li>
            </ul>
          </p>
        </div>
        <div className="residencySection">
          <h2>What is the cost?</h2>
          <p>
            ~$1400/mo per person to pay for rent + ~$100/mo for programming.
            Please do not be discouraged from applying if you cannot afford
            residency; some spots will be available for sponsorship.
          </p>
        </div>
        <div className="residencySection">
          <h2>How long is the program?</h2>
          <p>
            Currently, we are running 4-month cycles. There will be other ways
            for non-residents to get involved such as through coworking sessions
            and events. Details to come.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Residency;
